<template>
  <div v-if="showList">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="hintText"
      @load="onLoad"
    >
      <div class="order-card" v-for="item in orderList" :key="item.id">
        <div class="title-row">
          <img :src="item.head_img" class="shop-img" />
          <span
            class="title-text"
            @click="
              goToShop(
                orderClass.order_type * 1 === 1 ? item.sell_id : item.buy_id
              )
            "
            >{{ item.nickname }}</span
          >
          <van-icon name="arrow" size="17" color="#9B9B9B" />
          <div class="pay-status">{{ item.status_str }}</div>
        </div>
        <div class="goods-info">
          <img :src="item.used_img || defaultsImg" class="goods-img" />
          <div class="info-content">
            <div class="title-content-box" @click="lookOrderDetail(item.id)">
              <span class="title-content">
                {{ item.used_name }}
              </span>
            </div>
            <div class="price">
              <span class="small">￥</span>
              <span class="integer">{{ toInteger(item.price) }}</span>
              <span class="small">{{ twoFloating(item.price) }}</span>
            </div>
          </div>
          <div class="quantity">{{ "x1" }}</div>
        </div>
        <van-divider
          v-if="
            (orderClass.order_type === 1 &&
              (item.status === 1 ||
                (item.status === 2 && item.shipping_status >= 2))) ||
            (orderClass.order_type === 2 &&
              item.status === 2 &&
              item.shipping_status <= 1 &&
              item.deliver_type !== 3)
          "
        />
        <div class="button-box">
          <div class="button-box" v-if="orderClass.order_type === 1">
            <div class="button-box" v-if="item.status === 1">
              <div class="button">
                <van-button
                  plain
                  block
                  round
                  @click="operateBtn(item, 'cancel')"
                >
                  {{ "取消订单" }}
                </van-button>
              </div>
              <div class="button" v-if="item.deliver_type !== 3">
                <van-button
                  plain
                  block
                  round
                  @click="operateBtn(item, 'update')"
                >
                  {{ "修改地址" }}
                </van-button>
              </div>
              <div class="button">
                <van-button
                  plain
                  block
                  round
                  type="danger"
                  @click="operateBtn(item, 'pay')"
                >
                  {{ "立即付款" }}
                </van-button>
              </div>
            </div>
            <div
              class="button-box"
              v-else-if="
                item.status === 2 &&
                (item.shipping_status >= 2 || item.deliver_type === 3)
              "
            >
              <div class="button" v-if="item.shipping_status >= 2">
                <van-button
                  plain
                  block
                  round
                  @click="operateBtn(item, 'schedule')"
                  v-if="item.deliver_type !== 3"
                >
                  {{ "查看物流" }}
                </van-button>
              </div>
              <div class="button">
                <van-button
                  plain
                  block
                  round
                  type="danger"
                  @click="operateBtn(item, 'decision')"
                >
                  {{ item.deliver_type !== 3 ? "确认收货" : "确认取货" }}
                </van-button>
              </div>
            </div>
          </div>
          <div class="button-box" v-else>
            <div
              class="button"
              v-if="
                item.status === 2 &&
                item.shipping_status <= 1 &&
                item.deliver_type !== 3
              "
            >
              <van-button
                plain
                block
                round
                type="danger"
                @click="shipments(item.id)"
              >
                {{ "发货" }}
              </van-button>
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <Shipments
      :isShow="showShipments"
      :orderId="orderId"
      @closePopup="closePopup"
      @startOrder="updateList"
    ></Shipments>
    <PayOrder
      :showPay="showPay"
      :orderId="orderId"
      @closeOverlay="closeOverlay"
    ></PayOrder>
    <BlankPage :blank="blank" id="blank-page" v-if="showBlank"></BlankPage>
    <SelectAddress :isShow="isAddress" @getAddress="getAddress"></SelectAddress>
  </div>
</template>

<script>
import SelectAddress from "@/components/select-address/select-address";
import Shipments from "../../components/shipments/shipments";
import BlankPage from "@/components/blank-page/blank-page.vue";
import PayOrder from "../../pay-order";
import {
  getOrderList,
  cancelOrder,
  setAffirmOrder,
  updateAddress,
} from "@/api/used";
import { Dialog, Toast } from "vant";
import tools from "@/common/js/tools";
export default {
  name: "order-list",
  components: {
    BlankPage,
    Shipments,
    PayOrder,
    SelectAddress,
  },
  props: {
    orderClass: {
      type: Object,
    },
  },
  data() {
    return {
      isAddress: false,
      loading: false,
      finished: false,
      showBlank: false,
      hintText: "没有更多了",
      showShipments: false,
      orderId: 0,
      orderList: [],
      defaultsImg: require("@/assets/used/shop_logo.png"),
      showPay: false,
      blank: {
        imgUrl: require("@/assets/common/no_evaluation_icon_is_available.png"),
        text: "此订单你暂时还没有哦！",
      },
      showList: true,
    };
  },
  mounted() {
    // this.orderList = [];
  },
  watch: {
    orderClass: {
      deep: true,
      handler(n, o) {
        console.log(n);
        this.$emit("updateLoading", true);
      },
    },
  },
  methods: {
    selectAddress() {
      // 打开地址选择
      this.isAddress = true;
    },
    async getAddress(locationId) {
      // 关闭地址选择（回调）
      this.isAddress = false;
      if (locationId === undefined || locationId * 1 <= 0) {
        return false;
      }
      this.locationId = locationId;

      const res = await updateAddress({
        order_id: this.orderId,
        address_id: locationId,
      });
      if (res.code === 1) {
        Toast.success("地址修改成功");
      } else {
        Toast.fail(res.msg);
      }
    },
    updateList() {
      this.getOrderList("order");
    },
    onLoad() {
      this.getOrderList("order");
    },
    shipments(id) {
      //开启发货
      this.orderId = id;
      this.showShipments = true;
    },
    closePopup() {
      //关闭发货
      this.showShipments = false;
    },
    closeOverlay() {
      // 支付界面关闭的回调
      this.showPay = false;
    },
    operateBtn(item, str) {
      // 操作按钮
      let text = "";
      if (str === "cancel") {
        text = "取消订单";
      } else if (str === "decision") {
        text = "确认收货";
      } else if (str === "pay") {
        this.orderId = item.id;
        this.showPay = true;
        return;
      } else if (str === "schedule") {
        this.$router.push({
          name: "look-logistics",
          query: { orderId: item.id },
        });
        return;
      } else if (str === "update") {
        this.isAddress = true;
        this.orderId = item.id;
        return true;
      }
      Dialog.confirm({
        title: "提示",
        message: `你是否决定${text}？`,
      })
        .then(() => {
          this.orderId = item.id;
          if (str === "decision") {
            this.setAffirmOrder();
          } else {
            this.cancelOrder();
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    lookOrderDetail(id) {
      this.$router.push({
        name: "used-order-details",
        query: {
          orderId: id,
          orderType: this.orderClass.order_type,
        },
      });
    },
    goToShop(shopId) {
      this.$router.push({
        name: "seller-info",
        query: {
          shopId: shopId,
        },
      });
    },
    async getOrderList(str) {
      // 获取订单列表
      const res = await getOrderList(this.orderClass);
      console.log(res);
      if (res.code > 0) {
        this.showList = true;
        this.$emit("updateLoading", false);
      }
      if (this.orderList.length >= res.data.total) {
        this.finished = true;
      }
      this.$emit("backColorFn", res.data.total);
      if (res.data.total <= 0) {
        this.showBlank = true;
        this.hintText = "";
        this.orderList = [];
        return;
      }
      this.hintText = "没有更多了";
      this.showBlank = false;
      this.loading = false;
      if (str === "order") {
        this.orderList = res.data.items;
      } else {
        this.orderList = [...this.orderList, ...res.data.items];
      }
      // console.log("触发res----", res);
    },
    async cancelOrder() {
      // 取消订单
      const res = await cancelOrder({ order_id: this.orderId });
      console.log(res);
      if (res.code === 1) {
        Toast.success("取消成功");
        this.updateList();
      }
    },
    async setAffirmOrder() {
      const res = await setAffirmOrder({ order_id: this.orderId });
      console.log(res);
      if (res.code === 1) {
        Toast.success("操作成功");
        this.updateList();
      }
    },
    toInteger(num) {
      // 取整
      let toNum = "";
      toNum = parseInt(num);
      return toNum;
    },
    twoFloating(num) {
      // 获取两位小数
      return tools.twoFloating(num);
      // let price = "";
      // if (typeof num === "number") {
      //   price = String(num).split(".")[1];
      //   if (price !== undefined && price.length === 1) {
      //     price = `.${price}0`;
      //   } else {
      //     price === undefined ? (price = ".00") : (price = `.${price}`);
      //   }
      // }
      // return price;
    },
  },
};
</script>

<style lang="less" scoped>
.order-card {
  background-color: #fff;
  border-radius: 13px;
  padding: 14px;
  margin: 0 12px 12px 12px;
}
.title-row {
  position: relative;
  display: flex;
  align-items: center;
  .shop-img {
    display: block;
    width: 20px;
    height: 20px;
  }
  .title-text {
    font-size: 17px;
    font-weight: 500;
    margin-left: 8px;
    margin-right: 12px;
  }
  .pay-status {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 17px;
    color: #ed301d;
  }
}
.goods-info {
  position: relative;
  display: flex;
  margin-top: 14px;
  .info-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .quantity {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 16px;
    color: #aaaaaa;
  }
  .goods-img {
    flex-shrink: 0;
    display: block;
    width: 90px;
    height: 90px;
    margin-right: 10px;
  }
  .title-content-box {
    max-width: 180px;
  }
  .title-content {
    font-size: 16px;
    padding-right: 30px;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 2; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
    word-wrap: break-word;
  }
  .price {
    font-size: 12px;
    .integer {
      font-size: 17px;
      font-weight: 500;
    }
  }
}
.van-divider {
  margin: 12px 0;
}
.button-box {
  display: flex;
  justify-content: flex-end;
  .button {
    margin-left: 12px;
    width: 98px;
  }
}
/deep/.van-button--block {
  height: 38px;
}
/deep/.van-button__text {
  font-size: 16px;
}
.van-button--danger {
  border: 1px solid #ed301d;
}
.van-button--default {
  border: 1px solid #b9babe;
}
#blank-page {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 90%);
}
</style>
