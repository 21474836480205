var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showList)?_c('div',[_c('van-list',{attrs:{"finished":_vm.finished,"finished-text":_vm.hintText},on:{"load":_vm.onLoad},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.orderList),function(item){return _c('div',{key:item.id,staticClass:"order-card"},[_c('div',{staticClass:"title-row"},[_c('img',{staticClass:"shop-img",attrs:{"src":item.head_img}}),_c('span',{staticClass:"title-text",on:{"click":function($event){return _vm.goToShop(
              _vm.orderClass.order_type * 1 === 1 ? item.sell_id : item.buy_id
            )}}},[_vm._v(_vm._s(item.nickname))]),_c('van-icon',{attrs:{"name":"arrow","size":"17","color":"#9B9B9B"}}),_c('div',{staticClass:"pay-status"},[_vm._v(_vm._s(item.status_str))])],1),_c('div',{staticClass:"goods-info"},[_c('img',{staticClass:"goods-img",attrs:{"src":item.used_img || _vm.defaultsImg}}),_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"title-content-box",on:{"click":function($event){return _vm.lookOrderDetail(item.id)}}},[_c('span',{staticClass:"title-content"},[_vm._v(" "+_vm._s(item.used_name)+" ")])]),_c('div',{staticClass:"price"},[_c('span',{staticClass:"small"},[_vm._v("￥")]),_c('span',{staticClass:"integer"},[_vm._v(_vm._s(_vm.toInteger(item.price)))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.twoFloating(item.price)))])])]),_c('div',{staticClass:"quantity"},[_vm._v(_vm._s("x1"))])]),(
          (_vm.orderClass.order_type === 1 &&
            (item.status === 1 ||
              (item.status === 2 && item.shipping_status >= 2))) ||
          (_vm.orderClass.order_type === 2 &&
            item.status === 2 &&
            item.shipping_status <= 1 &&
            item.deliver_type !== 3)
        )?_c('van-divider'):_vm._e(),_c('div',{staticClass:"button-box"},[(_vm.orderClass.order_type === 1)?_c('div',{staticClass:"button-box"},[(item.status === 1)?_c('div',{staticClass:"button-box"},[_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"plain":"","block":"","round":""},on:{"click":function($event){return _vm.operateBtn(item, 'cancel')}}},[_vm._v(" "+_vm._s("取消订单")+" ")])],1),(item.deliver_type !== 3)?_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"plain":"","block":"","round":""},on:{"click":function($event){return _vm.operateBtn(item, 'update')}}},[_vm._v(" "+_vm._s("修改地址")+" ")])],1):_vm._e(),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"plain":"","block":"","round":"","type":"danger"},on:{"click":function($event){return _vm.operateBtn(item, 'pay')}}},[_vm._v(" "+_vm._s("立即付款")+" ")])],1)]):(
              item.status === 2 &&
              (item.shipping_status >= 2 || item.deliver_type === 3)
            )?_c('div',{staticClass:"button-box"},[(item.shipping_status >= 2)?_c('div',{staticClass:"button"},[(item.deliver_type !== 3)?_c('van-button',{attrs:{"plain":"","block":"","round":""},on:{"click":function($event){return _vm.operateBtn(item, 'schedule')}}},[_vm._v(" "+_vm._s("查看物流")+" ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"plain":"","block":"","round":"","type":"danger"},on:{"click":function($event){return _vm.operateBtn(item, 'decision')}}},[_vm._v(" "+_vm._s(item.deliver_type !== 3 ? "确认收货" : "确认取货")+" ")])],1)]):_vm._e()]):_c('div',{staticClass:"button-box"},[(
              item.status === 2 &&
              item.shipping_status <= 1 &&
              item.deliver_type !== 3
            )?_c('div',{staticClass:"button"},[_c('van-button',{attrs:{"plain":"","block":"","round":"","type":"danger"},on:{"click":function($event){return _vm.shipments(item.id)}}},[_vm._v(" "+_vm._s("发货")+" ")])],1):_vm._e()])])],1)}),0),_c('Shipments',{attrs:{"isShow":_vm.showShipments,"orderId":_vm.orderId},on:{"closePopup":_vm.closePopup,"startOrder":_vm.updateList}}),_c('PayOrder',{attrs:{"showPay":_vm.showPay,"orderId":_vm.orderId},on:{"closeOverlay":_vm.closeOverlay}}),(_vm.showBlank)?_c('BlankPage',{attrs:{"blank":_vm.blank,"id":"blank-page"}}):_vm._e(),_c('SelectAddress',{attrs:{"isShow":_vm.isAddress},on:{"getAddress":_vm.getAddress}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }