<template>
  <div
    class="page"
    :style="{ 'background-color': isBack ? '#f2f2f2' : '#fff' }"
  >
    <div class="back-color" v-if="isBack"></div>
    <nav class="nav-tabs">
      <van-icon name="arrow-left" size="20" color="#9B9B9B" @click="lastStep" />
      <div class="order-text">
        <span
          class="order-type"
          :class="{ 'order-color': isColor === 'buy' }"
          @click="orderClick('buy')"
        >
          购买订单
        </span>
        <span
          class="order-type"
          :class="{ 'order-color': isColor === 'sale' }"
          @click="orderClick('sale')"
        >
          卖出订单
        </span>
      </div>
      <van-popover
        v-model="showPopover"
        :actions="actions"
        placement="bottom-end"
        theme="dark"
        @select="onSelect"
      >
        <template #reference>
          <van-icon
            name="ellipsis"
            @touchend="ellipsis"
            size="20"
            color="#9B9B9B"
            class="icon-font"
          />
        </template>
      </van-popover>
    </nav>
    <!-- tab页 -->
    <van-tabs
      v-model="orderClass.order_status"
      animated
      title-active-color="#ED301D"
      title-inactive-color="#000000"
      class="van--tabs"
      @change="tabChange"
      ref="ref"
    >
      <van-tab title="全部" :name="0" title-class="title-text"> </van-tab>
      <van-tab title="待支付" :name="1" title-class="title-text"> </van-tab>
      <van-tab title="交易中" :name="2" title-class="title-text"> </van-tab>
      <van-tab title="交易完成" :name="3" title-class="title-text"> </van-tab>
    </van-tabs>
    <OrderList
      ref="orderList"
      :type="type"
      :orderClass="orderClass"
      class="order-list"
      @backColorFn="backColorFn"
      @updateLoading="updateLoading"
    ></OrderList>
    <div class="loading" v-if="showLoading">
      <van-loading type="spinner" color="#fff" />
    </div>
  </div>
</template>

<script>
import OrderList from "../../components/order-list/order-list";
export default {
  name: "ascertain-order",
  components: {
    OrderList,
  },
  data() {
    return {
      showLoading: false,
      active: 0,
      isColor: "buy",
      isBack: true,
      type: 0,
      orderClass: {
        order_type: 1,
        order_status: 0,
      },
      showPopover: false,
      actions: [
        { text: "返回首页", icon: "wap-home-o" },
        { text: "购物车", icon: "cart-o" },
        { text: "分类", icon: "cluster-o" },
        { text: "我的", icon: "friends-o" },
      ],
    };
  },
  mounted() {},
  methods: {
    orderClick(str) {
      this.isColor = str;
      str === "buy"
        ? (this.orderClass.order_type = 1)
        : (this.orderClass.order_type = 2);
      this.$refs.orderList.updateList();
    },
    tabChange(num) {
      this.orderClass.order_status = num;
      this.$refs.orderList.updateList();
    },
    backColorFn(total) {
      total <= 0 ? (this.isBack = false) : (this.isBack = true);
    },
    lastStep() {
      // this.$router.push("home");
      this.$router.go(-1);
    },
    ellipsis() {
      //  操作选项框
      this.showPopover = !this.showPopover;
    },
    updateLoading(bool) {
      this.showLoading = bool;
    },
    onSelect(action, index) {
      if (index === 0) {
        this.$router.push("/");
      } else if (index === 1) {
        this.$router.push("/shop-cart");
      } else if (index === 2) {
        this.$router.push("/classify");
      } else if (index === 3) {
        this.$router.push("/my");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  background-color: #f2f2f2;
  box-sizing: border-box;
  .back-color {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 210px;
    background: linear-gradient(to bottom, #ffffff, #ffffff, #f2f2f2);
  }
}
.page-color {
  background-color: #f2f2f2;
}
.nav-tabs {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 58px;
  padding: 0 12px;
  background-color: #fff;
  border-bottom: 1px solid rgb(241, 241, 241);
  .order-text {
    display: flex;
    justify-content: space-between;
    font-size: 21px;
    color: #6f6f6f;
    width: 222px;
    span {
      width: 84px;
    }
    .order-color {
      color: #000;
      font-weight: 500;
    }
    .order-type {
      display: block;
      width: 96px;
    }
  }
}
.van--tabs {
  margin-top: 58px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1999;
}
.order-list {
  position: relative;
  padding-top: 120px;
  padding-bottom: 64px;
}
/deep/.title-text {
  font-size: 18px;
}
/deep/.van-tabs__wrap {
  height: 52px;
}
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 60px;
  height: 60px;
  border-radius: 6px;
}
</style>